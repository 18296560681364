import React, { useState, useEffect } from "react";

import { Link } from "gatsby";

import MapWidget from "@accrosoft-ltd/accropress-websites-components/dist/esm/mapWidget";

import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/layout";

import fetchJsonp from "fetch-jsonp";

import "url-search-params-polyfill";

import "@accrosoft-ltd/vf-careers-site-theme/src/components/leaflet.css";

import MiniAppFormForm from "@accrosoft-ltd/vf-careers-site-theme/src/components/miniAppFormForm";

import VF_Share_Buttons from "@accrosoft-ltd/vf-careers-site-theme/src/modules/SmartFeedIndividualComponents/VF_Share_Buttons";

import { useCookies } from "react-cookie";
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from "@accrosoft-ltd/vf-careers-site-theme/src/components/analytics";
import { useTracking } from "react-tracking";

const isBase64 = require("is-base64");

const base64url = require("base64url");

require("es6-promise").polyfill();

const {
  activateApplicationForm,
} = require("@accrosoft-ltd/vf-careers-site-theme/src/components/miniAppFormFormFunctions");

const {
  CheckJobHasNotExpired,
  generateGoogleJobsSchema,
  listenForIFrameHeight,
  calculateSalary,
  FormatDateTime,
} = require("@accrosoft-ltd/vf-careers-site-theme/src/modules/utils");

function retrieveBackgroundImage(region, contentDataData) {
  if (region === "Head Office") {
    return "https://cdn1.accropress.com/OaklandCare/VacancyPages/HeadOffice.jpg?v2=2";
  } else if (region === "Woodland Grove") {
    return "https://cdn1.accropress.com/OaklandCare/VacancyPages/WoodlandGrove.jpg";
  } else if (region === "Lambwood Heights") {
    return "https://cdn1.accropress.com/OaklandCare/VacancyPages/LambwoodHeights.jpg";
  } else if (region === "Hastings Court") {
    return "https://cdn1.accropress.com/OaklandCare/VacancyPages/HastingsCourt.jpg";
  } else if (region === "Elsyng House") {
    return "https://cdn1.accropress.com/OaklandCare/VacancyPages/ElsyngHouse.jpg";
  } else if (region === "Elmbrook Court") {
    return "https://cdn1.accropress.com/OaklandCare/VacancyPages/ElmbrookCourt.jpg";
  } else if (region === "Beechwood Grove") {
    return "https://cdn1.accropress.com/OaklandCare/VacancyPages/BeechwoodGrove.jpg";
  }

  return "https://cdn1.accropress.com/OaklandCare/VacancyPages/HeadOffice.jpg?v2=2";
}

const SmartfeedIndividual = ({
  id = 1,
  vacancyTitlePath = false,
  apiKey,
  groupOrIdParam,
  smartfeedCompanyData,
  appGatewayURL,
  vacanciesRouteLoading,
  vacanciesRouteError,
  location,
  siteConfig,
}) => {
  const params = new URLSearchParams(location.search);
  const extraDataEncoded = params ? params.get("ExtraData") : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split("|")
    : [];
  const [isMobile, setIsMobile] = useState("");

  const [vacancy, setVacancy] = useState([]);
  const [showApplication, setshowApplication] = useState(false);
  const [applicationFormLoading, setApplicationFormLoading] = useState(false);
  const [miniAppFormToken, setMiniAppFormToken] = useState("");
  const [miniAppFormJSON, setMiniAppFormJSON] = useState("");
  const [gdprStatementJSON, setGdprStatementJSON] = useState("");
  const [jobSourcesJSON, getJobSourcesJSON] = useState([]);
  const [showMiniAppForm, setShowMiniAppForm] = useState(false);
  const [showMiniAppFormFrameLoading, setShowMiniAppFormFrameLoading] =
    useState(false);
  const [miniAppFormSubmitted, setMiniAppFormSubmitted] = useState(false);
  const [MiniAppError, setMiniAppError] = useState("");
  const [MiniAppFormIframe, setMiniAppFormIframe] = useState("");
  const [vacancyTitle, setVacancyTitle] = useState(
    extraDataDecodedSplit &&
      extraDataDecodedSplit[0] &&
      extraDataDecodedSplit[1]
      ? extraDataDecodedSplit[0] + " | " + extraDataDecodedSplit[1]
      : extraDataDecodedSplit && extraDataDecodedSplit[0]
      ? extraDataDecodedSplit[0]
      : "Loading vacancy..."
  );
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [hasCVUploadedStatus, setHasCVUploadedStatus] = useState(null);

  const [cookies, setCookie] = useCookies(["ap-signals-user-id"]);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    "ap-signals-session-id"
  );

  const { trackEvent } = useTracking(
    {
      page: "SmartfeedIndividual",
      location,
      apiKey,
      id,
      AdvertReference: vacancy.AdvertReference,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies["ap-signals-user-id"],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      location,
      cookies["ap-signals-user-id"],
      sessionIDValue,
      siteConfig
    );
  }, []);

  useEffect(() => {
    if (!vacanciesRouteLoading && !vacanciesRouteError) {
      listenForIFrameHeight();
      setshowApplication(false);

      let searchParams = new URLSearchParams(location.search);
      let entries = searchParams.entries();

      let vacancyPageAction = null;

      for (let pair of entries) {
        if (pair[0] === "action") {
          vacancyPageAction = pair[1];
        }
      }

      setTimeout(function () {
        setLoadingText(
          <span>
            If this is taking too long, please{" "}
            <Link to="/">go back to the search page</Link> and re-select a
            vacancy.
          </span>
        );
      }, 3000);

      const timeOut = setTimeout(() => {
        if (isBase64(decodeURIComponent(id)) && vacancyTitlePath) {
          fetchVacancy(
            new Buffer(decodeURIComponent(id), "base64").toString(),
            vacancyPageAction
          );
        } else {
          fetchVacancy(decodeURIComponent(id), vacancyPageAction);
        }
      }, 25);
      return () => clearTimeout(timeOut);
    }
  }, [id, vacanciesRouteLoading]);

  const fetchVacancy = async (id, vacancyPageAction) => {
    setLoading(true);
    try {
      fetchJsonp(siteConfig.sfAPIEndpoint + "/CareerPage/GetItem?id=" + id, {
        timeout: 15000,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const responseVacancy = json;
          const resultVacancy = responseVacancy;
          if (resultVacancy) {
            setLoading(false);
            setVacancy(resultVacancy);
            setVacancyTitle(resultVacancy.JobTitle);

            if (
              vacancyPageAction === "applyNow" &&
              siteConfig.useMiniAppForm === true
            ) {
              trackEvent({
                eventAction: "activateApplicationForm_QueryString",
                eventLabel: resultVacancy.AdvertReference,
              });
              activateApplicationForm(
                siteConfig,
                true,
                setshowApplication,
                setApplicationFormLoading,
                setMiniAppFormJSON,
                setGdprStatementJSON,
                getJobSourcesJSON,
                setShowMiniAppForm,
                setShowMiniAppFormFrameLoading,
                resultVacancy.AdvertId,
                resultVacancy.Token,
                resultVacancy.RequiresCv,
                MiniAppError,
                setMiniAppError,
                miniAppFormToken,
                setMiniAppFormToken,
                false,
                resultVacancy.CompanyId,
                trackEvent
              );

              setshowApplication(true);
            }

            return resultVacancy;
          }
        })
        .catch(function (ex) {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint +
              `/CareerPage/GetItem?id=${new Buffer(id, "base64").toString()}`,
            {
              timeout: 15000,
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              const responseVacancy = json;
              const resultVacancy = responseVacancy;
              if (resultVacancy) {
                setLoading(false);
                setVacancy(resultVacancy);
                setVacancyTitle(resultVacancy.JobTitle);

                if (
                  vacancyPageAction === "applyNow" &&
                  siteConfig.useMiniAppForm === true
                ) {
                  trackEvent({
                    eventAction: "activateApplicationForm_QueryString",
                    eventLabel: resultVacancy.AdvertReference,
                  });
                  activateApplicationForm(
                    siteConfig,
                    true,
                    setshowApplication,
                    setApplicationFormLoading,
                    setMiniAppFormJSON,
                    setGdprStatementJSON,
                    getJobSourcesJSON,
                    setShowMiniAppForm,
                    setShowMiniAppFormFrameLoading,
                    resultVacancy.AdvertId,
                    resultVacancy.Token,
                    resultVacancy.RequiresCv,
                    MiniAppError,
                    setMiniAppError,
                    miniAppFormToken,
                    setMiniAppFormToken,
                    false,
                    resultVacancy.CompanyId,
                    trackEvent
                  );

                  setshowApplication(true);
                }

                return resultVacancy;
              }
            })
            .catch(function (ex) {
              setLoading(false);
              console.debug(ex, "error");
            });
        });

      /*  
     JSON method, sadly doesn't work with SF
     const fetchingVacancy = await fetch(
        siteConfig.sfAPIEndpoint + `/CareerPage/GetItem?callback=gatsby&id=${id}`
      )
      const responseVacancy = await fetchingVacancy.json()
      const resultVacancy = await responseVacancy
      setLoading(false)
      setVacancy(resultVacancy)
      return resultVacancy 
      */
    } catch (error) {
      setLoading(false);
      console.debug(error.message, "error");
    }
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={location}
      apiKey={apiKey}
      groupOrIdParam={groupOrIdParam}
      appGatewayURL={appGatewayURL}
      smartfeedCompanyData={smartfeedCompanyData}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      trackEvent={trackEvent}
      title={
        loading
          ? extraDataDecodedSplit &&
            extraDataDecodedSplit[0] &&
            extraDataDecodedSplit[1]
            ? extraDataDecodedSplit[0] + " | " + extraDataDecodedSplit[1]
            : extraDataDecodedSplit && extraDataDecodedSplit[0]
            ? extraDataDecodedSplit[0]
            : "Loading vacancy..."
          : vacancyTitle
      }
      description={
        loading
          ? extraDataDecodedSplit && extraDataDecodedSplit[4]
            ? extraDataDecodedSplit[4]
            : extraDataDecodedSplit && extraDataDecodedSplit[1]
            ? "Check out this vacancy from " + extraDataDecodedSplit[1]
            : "Check out this vacancy."
          : vacancy.Summary
      }
    >
      <div className="main-container main-container--single">
        {!vacanciesRouteLoading &&
          (!vacancy || vacancy === [] || !vacancy.Description) && (
            <div className="wrapper wrapper--not-mobile">
              <section
                className="page-hero vacs-homepage-hero vacs-loading-hero"
                style={{
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="u-opacity-layer"></div>
                <div className="page-hero__inner">
                  <div className="page-hero__content">
                    <h1>Loading vacancy...</h1>
                    <p className="small-padding">
                      Please wait while we load the vacancy that you requested.
                    </p>
                    {loadingText && <p>{loadingText}</p>}
                  </div>
                </div>
              </section>
            </div>
          )}

        {!vacanciesRouteLoading &&
          vacancy &&
          vacancy != [] &&
          vacancy.Description && (
            <div>
              <div id="ApplicationScrollTarget"></div>
              {!showApplication ? (
                <>
                  {vacancy.PostingDate &&
                    vacancy.ExpiryDate &&
                    CheckJobHasNotExpired(vacancy.ExpiryDate) && (
                      <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                          __html: JSON.stringify(
                            generateGoogleJobsSchema(vacancy)
                          ),
                        }}
                      />
                    )}

                  <div className="wrapper wrapper--not-mobile">
                    <section
                      className="page-hero vacs-homepage-hero vacs-loading-hero"
                      style={{
                        backgroundImage: `url(${retrieveBackgroundImage(
                          vacancy.Region,
                          {}
                        )})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="u-opacity-layer"></div>
                      <div className="page-hero__inner">
                        <div className="page-hero__content page-hero__content__vacancy">
                          <div>
                            <h1 className="job-title-hero">
                              {vacancy.JobTitle}
                            </h1>
                            {vacancy.Summary && <p>{vacancy.Summary}</p>}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="vacancies-landing wrapper">
                    <div className="container">
                      <div className="row clearfix">
                        <div className="col-12 job-details-wrap">
                          <div className="site-spacing">
                            <div className="row vacancyDetailsWrap">
                              <div className="col-12 col-9-m job-description">
                                <a
                                  target="_blank"
                                  href={
                                    vacancy.Link +
                                    "&action=ApplyNow&iframe=False"
                                  }
                                  className="btn btn--action btn-applyforrole top-apply-btn mb-3"
                                >
                                  Apply now
                                </a>
                                {isMobile && (
                                  <div className="job-sidebar job-sidebar-mobile">
                                    <>
                                      {vacancy &&
                                        vacancy != [] &&
                                        vacancy.Description && (
                                          <div className="vf-details">
                                            {/* <div className="job-job-info-image">
                                            <img
                                              src={retrieveBackgroundImage(
                                                vacancy.Region,
                                                {}
                                              )}
                                            />
                                          </div> */}
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Job Reference:
                                              </div>
                                              <div className="vf-job-info-value vf-Location">
                                                {vacancy.AdvertReference}
                                              </div>
                                            </div>
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Carehome/Office:
                                              </div>
                                              <div className="vf-job-info-value vf-Location">
                                                {vacancy.Region}
                                              </div>
                                            </div>
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Location:
                                              </div>
                                              <div className="vf-job-info-value vf-Location">
                                                {vacancy.Location}
                                              </div>
                                            </div>
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Category:
                                              </div>
                                              <div className="vf-job-info-value">
                                                <span className="vf-category">
                                                  {vacancy.JobCategory}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Job Type:
                                              </div>
                                              <div className="vf-job-info-value">
                                                <span className="vf-category">
                                                  {vacancy.JobType}
                                                  {vacancy.JobTypeTime
                                                    ? " - " +
                                                      vacancy.JobTypeTime
                                                    : ""}
                                                </span>
                                              </div>
                                            </div>
                                            {vacancy.Duration && (
                                              <div className="vf-job-info-container">
                                                <div className="vf-job-info-title">
                                                  Category:
                                                </div>
                                                <div className="vf-job-info-value">
                                                  <span className="vf-category">
                                                    {vacancy.Duration}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Salary:
                                              </div>
                                              <div className="vf-job-info-value vf-Salary">
                                                {calculateSalary(
                                                  vacancy.Currency,
                                                  vacancy.SalaryFrom,
                                                  vacancy.SalaryTo,
                                                  vacancy.SalaryType,
                                                  vacancy.SalaryPeriod
                                                )}
                                              </div>
                                            </div>
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Benefits:
                                              </div>
                                              <div className="vf-job-info-value">
                                                <span className="vf-category">
                                                  {vacancy.Benefits}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="vf-job-info-container">
                                              <div className="vf-job-info-title">
                                                Closing Date:
                                              </div>
                                              <div className="vf-job-info-value vf-ExpiryDate">
                                                {FormatDateTime(
                                                  vacancy.ExpiryDate
                                                )}
                                              </div>
                                            </div>
                                            <div className="vf-job-info-container">
                                              {vacancy.Attachments &&
                                                vacancy.Attachments != [] &&
                                                vacancy.Attachments.length >=
                                                  0 && (
                                                  <ul className="vf-Attachments">
                                                    {vacancy.Attachments &&
                                                      vacancy.Attachments.map(
                                                        (attachment, i) => (
                                                          <li
                                                            key={i}
                                                            id={
                                                              attachment.S3Name
                                                            }
                                                            className="row"
                                                          >
                                                            <a
                                                              className="btn-filedownload vf-row"
                                                              href={
                                                                attachment.Location
                                                              }
                                                              target="_blank"
                                                            >
                                                              <span className="details vf-col-xs-8">
                                                                <i
                                                                  className="fileicon"
                                                                  className="fa fa-download fa-fw fa-2x"
                                                                ></i>
                                                                <span className="filename">
                                                                  {attachment.FileName.substring(
                                                                    0,
                                                                    24
                                                                  )}
                                                                  {attachment
                                                                    .FileName
                                                                    .length > 19
                                                                    ? "..."
                                                                    : ""}
                                                                </span>
                                                              </span>
                                                            </a>
                                                          </li>
                                                        )
                                                      )}
                                                  </ul>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  </div>
                                )}

                                <div className="vf-JobDescription">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: vacancy.Description,
                                    }}
                                  />
                                </div>

                                <div className="vf-map">
                                  {vacancy.Latitude != "" &&
                                    vacancy.Latitude != null &&
                                    vacancy.Latitude != 0 && (
                                      <MapWidget
                                        latitude={Number(vacancy.Latitude)}
                                        longitude={Number(vacancy.Longitude)}
                                        center={[
                                          Number(vacancy.Latitude),
                                          Number(vacancy.Longitude),
                                        ]}
                                        zoom="12"
                                        pitch="0"
                                        bearing="0"
                                        scrollZoom="false"
                                        pitchWithRotate="false"
                                        dragRotate="false"
                                      />
                                    )}
                                </div>

                                <div className="vf-jobApply-actions">
                                  <h4>Apply for this role</h4>
                                  <p>
                                    Click the button below to submit an
                                    application online.
                                  </p>
                                  <a
                                    target="_blank"
                                    href={
                                      vacancy.Link +
                                      "&action=ApplyNow&iframe=False"
                                    }
                                    className="btn btn--action btn-applyforrole"
                                  >
                                    Apply now
                                  </a>

                                  <Link
                                    className="btn btn--action btn-primary-inverted btn-backtosearch"
                                    to="/"
                                  >
                                    Go back to the search page
                                  </Link>

                                  <div id="share-icons">
                                    <div className="vf-SocialButtons">
                                      <div id="share-icons">
                                        <VF_Share_Buttons
                                          vacancyTitle={vacancyTitle}
                                          vacancy={vacancy}
                                          apiKey={apiKey}
                                          siteConfig={siteConfig}
                                          text="Not for you? Share it with someone else:"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {!isMobile && (
                                <div className="col-12 col-3-m job-sidebar">
                                  <>
                                    {vacancy &&
                                      vacancy != [] &&
                                      vacancy.Description && (
                                        <div className="vf-details">
                                          {/* <div className="job-job-info-image">
                                          <img
                                            src={retrieveBackgroundImage(
                                              vacancy.Region,
                                              {}
                                            )}
                                          />
                                        </div> */}
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Job Reference:
                                            </div>
                                            <div className="vf-job-info-value vf-Location">
                                              {vacancy.AdvertReference}
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Carehome/Office:
                                            </div>
                                            <div className="vf-job-info-value vf-Location">
                                              {vacancy.Region}
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Location:
                                            </div>
                                            <div className="vf-job-info-value vf-Location">
                                              {vacancy.Location}
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Category:
                                            </div>
                                            <div className="vf-job-info-value">
                                              <span className="vf-category">
                                                {vacancy.JobCategory}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Job Type:
                                            </div>
                                            <div className="vf-job-info-value">
                                              <span className="vf-category">
                                                {vacancy.JobType}
                                                {vacancy.JobTypeTime
                                                  ? " - " + vacancy.JobTypeTime
                                                  : ""}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Salary:
                                            </div>
                                            <div className="vf-job-info-value vf-Salary">
                                              {calculateSalary(
                                                vacancy.Currency,
                                                vacancy.SalaryFrom,
                                                vacancy.SalaryTo,
                                                vacancy.SalaryType,
                                                vacancy.SalaryPeriod
                                              )}
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Benefits:
                                            </div>
                                            <div className="vf-job-info-value">
                                              <span className="vf-category">
                                                {vacancy.Benefits}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            <div className="vf-job-info-title">
                                              Closing Date:
                                            </div>
                                            <div className="vf-job-info-value vf-ExpiryDate">
                                              {FormatDateTime(
                                                vacancy.ExpiryDate
                                              )}
                                            </div>
                                          </div>
                                          <div className="vf-job-info-container">
                                            {vacancy.Attachments &&
                                              vacancy.Attachments != [] &&
                                              vacancy.Attachments.length >=
                                                0 && (
                                                <ul className="vf-Attachments">
                                                  {vacancy.Attachments &&
                                                    vacancy.Attachments.map(
                                                      (attachment, i) => (
                                                        <li
                                                          key={i}
                                                          id={attachment.S3Name}
                                                          className="row"
                                                        >
                                                          <a
                                                            className="btn-filedownload vf-row"
                                                            href={
                                                              attachment.Location
                                                            }
                                                            target="_blank"
                                                          >
                                                            <span className="details vf-col-xs-8">
                                                              <i
                                                                className="fileicon"
                                                                className="fa fa-download fa-fw fa-2x"
                                                              ></i>

                                                              <span className="filename">
                                                                {attachment.FileName.substring(
                                                                  0,
                                                                  24
                                                                )}
                                                                {attachment
                                                                  .FileName
                                                                  .length > 19
                                                                  ? "..."
                                                                  : ""}
                                                              </span>
                                                            </span>
                                                          </a>
                                                        </li>
                                                      )
                                                    )}
                                                </ul>
                                              )}
                                          </div>

                                          <div className="vf-jobApply-actions vf-jobApply-actions-sidebar">
                                            <Link
                                              className="btn btn--action btn-primary-inverted btn-backtosearch"
                                              to="/"
                                            >
                                              Go back to the search page
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                  </>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="vacancy-application-wrapper">
                  <div className="vacancy-application-topbar vacancy-application-topbar---standard-app">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-6 job-title-app">
                          <h1>{vacancyTitle + " application"}</h1>
                        </div>
                        <div className="col-12 col-lg-6 back-to-description">
                          <a
                            className="nav-action-sm"
                            onClick={() => {
                              trackEvent({
                                eventAction: "deactivateApplicationForm",
                                eventLabel: vacancy.AdvertReference,
                              });
                              activateApplicationForm(
                                siteConfig,
                                false,
                                setshowApplication,
                                setApplicationFormLoading,
                                setMiniAppFormJSON,
                                setGdprStatementJSON,
                                getJobSourcesJSON,
                                setShowMiniAppForm,
                                setShowMiniAppFormFrameLoading,
                                vacancy.AdvertId,
                                vacancy.Token,
                                vacancy.RequiresCv,
                                MiniAppError,
                                setMiniAppError,
                                miniAppFormToken,
                                setMiniAppFormToken,
                                false,
                                vacancy.CompanyId,
                                trackEvent
                              );
                            }}
                          >
                            Go back to the description
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {siteConfig.useMiniAppForm === true ? (
                    <div id="miniAppFormWrapper">
                      <div className="container">
                        <div id="miniAppForm">
                          {miniAppFormJSON &&
                          miniAppFormJSON.config &&
                          miniAppFormJSON.config.Fields ? (
                            <MiniAppFormForm
                              siteConfig={siteConfig}
                              showMiniAppForm={showMiniAppForm}
                              applicationFormLoading={applicationFormLoading}
                              miniAppFormJSON={miniAppFormJSON}
                              setMiniAppFormJSON={setMiniAppFormJSON}
                              miniAppFormToken={miniAppFormToken}
                              setMiniAppFormToken={setMiniAppFormToken}
                              gdprStatementJSON={gdprStatementJSON}
                              jobSourcesJSON={jobSourcesJSON}
                              setShowMiniAppForm={setShowMiniAppForm}
                              showMiniAppFormFrameLoading={
                                showMiniAppFormFrameLoading
                              }
                              setShowMiniAppFormFrameLoading={
                                setShowMiniAppFormFrameLoading
                              }
                              miniAppFormSubmitted={miniAppFormSubmitted}
                              setMiniAppFormSubmitted={setMiniAppFormSubmitted}
                              MiniAppFormIframe={MiniAppFormIframe}
                              setMiniAppFormIframe={setMiniAppFormIframe}
                              MiniAppError={MiniAppError}
                              setMiniAppError={setMiniAppError}
                              hasCVUploadedStatus={hasCVUploadedStatus}
                              setHasCVUploadedStatus={setHasCVUploadedStatus}
                              trackEvent={trackEvent}
                            />
                          ) : (
                            <div className="loading-panel">
                              <div className="loading-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <span className="loading-text">
                                Please wait while we load the form's
                                configuration...
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="vf_iFrame_wrapper">
                      <div className="container">
                        <iframe
                          id="vf_iFrame_application_form"
                          src={
                            vacancy.Link +
                            "&action=ApplyNow&HideCareersLink=true"
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
      </div>
    </Layout>
  );
};

export default SmartfeedIndividual;
