import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/layout';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '@accrosoft-ltd/vf-careers-site-theme/src/components/analytics';
import { useTracking } from 'react-tracking';

import SmartFeedFiltersComponent from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedWithFilters_WT';

require('es6-promise').polyfill();

export default function VacanciesListPage(props) {
  const [vacancyLoading, setVacancyLoading] = useState(true);

  const siteConfig = props.siteConfig;

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking(
    {
      page: 'VacanciesListPage',
      location: props.location,
      apiKey: props.apiKey,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies['ap-signals-user-id'],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      props.location,
      cookies['ap-signals-user-id'],
      sessionIDValue,
      siteConfig
    );
  }, []);

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={trackEvent}
      title={`${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName
      } Careers`}
      description={`Careers at ${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName
      }`}
    >
      {!props.vacanciesRouteLoading &&
      (siteConfig.siteConfig || props.smartfeedCompanyData) &&
      props.apiKey ? (
        <>
          <SmartFeedFiltersComponent
            trackEvent={trackEvent}
            siteConfig={siteConfig}
            setVacancyLoading={setVacancyLoading}
            vacancyLoading={vacancyLoading}
            apiKey={siteConfig.apiKey || props.apiKey}
            group={
              siteConfig.group
                ? siteConfig.group
                : props.groupOrIdParam === 'id'
                ? false
                : props.groupOrIdParam === 'group'
                ? true
                : false
            }
            gdprKey={siteConfig.gdprKey || siteConfig.apiKey || props.apiKey}
            smartfeedCompanyData={
              siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
            }
            CompanyGroupName={
              siteConfig.companyName || props.smartfeedCompanyData.CompanyName
            }
            OptInLink={
              siteConfig.companyCareersSite
                ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
                : siteConfig.OptInLink ||
                  `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/optin/?OptInID=`
            }
            UnsubscribeLink={
              siteConfig.companyCareersSite
                ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
                : siteConfig.UnsubscribeLink ||
                  `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/unsubscribe/?UnsubscribeID=`
            }
            VacancyPageBaseURL={
              siteConfig.companyCareersSite
                ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
                : siteConfig.VacancyPageBaseURL ||
                  `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/vacancy/`
            }
            ReturnToSearchURL={
              siteConfig.companyCareersSite
                ? `${siteConfig.careersSiteDomain}/vacancies/`
                : siteConfig.ReturnToSearchURL ||
                  `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/`
            }
            contentDataData={props.contentDataData || {}}
            location={siteConfig.showLocationJA}
            region={siteConfig.showRegionJA}
            category={siteConfig.showCategoryJA}
            industry={siteConfig.showIndustryJA}
            jobType={siteConfig.showJobTypeJA}
            jobTime={siteConfig.showJobTimeJA}
          />
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
